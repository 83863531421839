import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { themeJson } from "./theme";
import "./index.css";
import { json } from "./json";

const API_URL = "https://survey-api-290076879844.us-central1.run.app";

async function postResults(survey, options) {
    options.showSaveInProgress();

    // Crear objeto de respuesta usando el survey_id existente
    const surveyResponse = {
        survey_id: json.surveyId,  // Este es el ID de la plantilla de la encuesta
        data: survey.data,         // Respuestas del usuario
        completed_at: new Date().toISOString(),
        user_agent: navigator.userAgent || null,
        response_time: Math.floor(survey.timeSpent) || 0
    };

    console.log('Enviando respuesta para survey_id:', json.surveyId);

    try {
        const response = await fetch(`${API_URL}/survey-responses`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(surveyResponse)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error en la respuesta:', errorData);
            throw new Error(JSON.stringify(errorData));
        }

        const result = await response.json();
        options.showSaveSuccess("¡Gracias por completar la encuesta!");
        return result;
    } catch (error) {
        console.error("Error al guardar la encuesta:", error);
        options.showSaveError("Error al guardar las respuestas. Por favor, intente nuevamente.");
        throw error;
    }
}

function SurveyComponent() {
    const survey = new Model(json);
    survey.applyTheme(themeJson);

    survey.onComplete.add(async (sender, options) => {
        try {
            await postResults(sender, options);
            console.log("Respuesta guardada exitosamente");
        } catch (error) {
            console.error("Error al guardar las respuestas:", error);
        }
    });

    return (<Survey model={survey} />);
}

export default SurveyComponent;
